<template>
  <div class="header-margin row">
    <div class="col-12 col-md-9">
      <q-item>
        <q-item-section avatar>
          <q-avatar class="avatar">
            <img
              :src="ecommData.partner_photo"
              :alt="ecommData.highlight_text"
            />
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label
            ><span class="text-uppercase">Boas Vindas!</span>
            <div class="influ-name q-mt-sm q-mb-md">
              <b>{{ ecommData.highlight_text }}</b>
            </div>
          </q-item-label>
          <q-item-label caption class="q-mt-lg description">{{
            ecommData.highlight_description
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </div>
    <div class="col-12 col-md-3 text-center">
      <div class="q-mt-lg">
        <div
          v-if="
            ecommCustomization.voucher &&
            Object.keys(ecommCustomization.voucher).length
          "
        >
          <span
            v-html="
              $t('landing_page.voucher', {
                number: ecommCustomization.voucher.discount,
                type:
                  ecommCustomization.voucher.type_discount == 'percent'
                    ? '%'
                    : 'R$',
              })
            "
          />
          <q-btn
            outline
            :label="ecommCustomization.voucher.number"
            icon-right="content_copy"
            class="q-mt-xs full-width"
            @click="copyLink(ecommCustomization.voucher.number)"
          />
        </div>
        <div v-else v-html="$t('landing_page.shareWithFriends')" />
        <q-btn
          :label="$t('global.share')"
          icon-right="share"
          class="q-mt-xs full-width"
          @click="share"
          :style="`background-color: ${
            layout.body_background !== '#E0E0E0'
              ? layout.menu_top_background
              : '#000'
          };
            color: ${layout.menu_itens_text_color};
          `"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCustomLayoutLP } from "@/shared/helpers/customLayoutHelper";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { defineProps, ref } from "vue";

defineProps({
  ecommData: {
    type: Object,
    required: true,
  },
  ecommCustomization: {
    type: Object,
    required: true,
  },
});

const share = () => {
  navigator.share({
    url: `${window.location.href}`,
  });
};

const layout = ref(getCustomLayoutLP("_LP"));

function copyLink(link) {
  if (!link) {
    notifyError("Nenhum link cupom para cópia.");
    return;
  }

  navigator.clipboard
    .writeText(link)
    .then(() => notifySuccess("Cupom copiado com sucesso!"))
    .catch(() => notifyError("Erro ao copiar o cupom."));
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 140px;
  height: 140px;

  img {
    border: 3px solid #3a3a3a;
  }
}

.header-margin {
  margin: 35px;
}

.influ-name {
  font-size: 20pt;
}

.description {
  font-size: 11pt;
}

@media screen and (max-width: 900px) {
  .avatar {
    width: 100px;
    height: 100px;

    img {
      border: 3px solid #3a3a3a;
    }
  }

  .influ-name {
    font-size: 12pt;
  }

  .header-margin {
    margin: 20px 0px 0px 0px;
  }

  .description {
    font-size: 10pt;
  }
}
</style>
