<template>
  <q-card
    class="card full-height"
    flat
    bordered
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <q-img :src="data.full_url" style="height: 240px" />

    <q-card-section>
      <q-item-label caption class="text-grey" style="font-size: 8pt">
        {{ data.brand_name }}
      </q-item-label>
      <div class="q-mt-sm q-mb-xs card-title" v-html="data.name" />
      <div class="q-mb-sm price-container">
        <div v-if="data.sale_price">
          <span
            :class="
              data.price_ref > data.sale_price ? 'discount' : 'non-discount'
            "
            >{{ formatToRealBR(data.price_ref) }}</span
          ><br />
          <span class="current-price"
            ><b>{{ formatToRealBR(data.sale_price) }}</b></span
          >
        </div>

        <div v-else>
          <span :class="'non-discount'">{{
            formatToRealBR(data.price_ref)
          }}</span
          ><br />
          <span class="current-price"
            ><b>{{ formatToRealBR(data.price_ref) }}</b></span
          >
        </div>

        <q-btn
          glossy
          v-if="hover || isMobile"
          :label="!isMobile ? $t('global.buy') : undefined"
          icon="shopping_cart"
          class="hover-button"
          @click="handleClick"
          :style="`background-color: ${
            layout.body_background !== '#E0E0E0'
              ? layout.menu_top_background
              : '#000'
          };
            color: ${layout.menu_itens_text_color};
          `"
        />
      </div>

      <div
        class="text-caption text-grey text-justify description"
        v-html="data.description"
      />
    </q-card-section>
  </q-card>
</template>

<script setup>
import { getCustomLayoutLP } from "@/shared/helpers/customLayoutHelper";
import { defineProps, ref, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

const hover = ref(false),
  route = useRoute(),
  unfluSlug = route.params.childSlug,
  isMobile = ref(window.innerWidth <= 900);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const layout = ref(getCustomLayoutLP("_LP"));

const handleClick = () =>
  window.open(
    props.data.original_link +
      `?utm_source=flipnet&utm_medium=influencer&utm_content=${unfluSlug}&utm_campaign=${unfluSlug}`,
    "_blank"
  );

// Função para detectar tamanho da tela
const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 900;
};

function formatToRealBR(value) {
  return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
}

// Adicionar event listener para redimensionamento da tela
onMounted(() => {
  window.addEventListener("resize", updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateIsMobile);
});
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 450px;
}

.card-title {
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hover-button {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px); /* Full width with margin on left and right */
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.3s ease;

  @media (max-width: 900px) {
    position: static;
    margin-left: auto;
    margin-right: 0;
    width: auto;
    opacity: 1;
    transition: none;
  }
}

.card:hover {
  scale: 1.02;
  transition: 0.5s;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
}

.card:hover .hover-button {
  opacity: 1;

  @media (max-width: 900px) {
    opacity: 1;
  }
}

.price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    justify-content: flex-start;
  }
}

.current-price {
  color: #3a3a3a;
  font-size: 16px;
}

.discount {
  text-decoration: line-through;
  font-size: 10px;
  color: #c96969;
}

.non-discount {
  opacity: 0;
  font-size: 10px;
}
</style>
