<template>
  <div
    :class="{
      'text-center': $q.screen.width > 900,
    }"
  >
    <q-tabs
      dense
      v-model="tab"
      inline-label
      outside-arrows
      mobile-arrows
      indicator-color="transparent"
      class="bg-transparent"
      active-color="black"
      v-if="$q.screen.width > 900"
    >
      <q-tab
        :name="i"
        :label="menu.title"
        v-for="(menu, i) in data"
        :key="i"
        class="scroll-btn"
        @click="scrollTo(menu.title)"
      />
    </q-tabs>
    <div v-else>
      <q-btn flat color="black" icon="menu" @click="menuDialog = true" />
    </div>
  </div>
  <hr v-if="data.length" />
  <q-dialog
    v-model="menuDialog"
    position="left"
    :maximized="true"
    style="margin: 0; padding: 0"
  >
    <q-card class="full-height" style="width: 220px">
      <q-list bordered separator>
        <q-item
          :key="i"
          clickable
          v-ripple
          v-for="(menu, i) in data"
          @click="handleMenuClick(menu.title)"
        >
          <q-item-section class="text-uppercase"
            ><b>{{ menu.title }}</b></q-item-section
          >
        </q-item>
      </q-list>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { defineProps, ref, nextTick } from "vue";

defineProps({
  data: {
    type: Array,
    required: true,
  },
});

const menuDialog = ref(false);
const tab = ref(0);

const handleMenuClick = (title) => {
  menuDialog.value = false;
  nextTick(() => {
    scrollTo(title);
  });
};

const scrollTo = (title) => {
  const element = document.getElementById(title);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  } else {
    console.warn(`Element with ID ${title} not found.`);
  }
};
</script>

<style lang="scss" scoped>
.scroll-btn {
  cursor: pointer;
  transition: color 0.5s;
}

.scroll-btn:hover {
  background-color: rgba(20, 20, 20, 0.15);
}
</style>
