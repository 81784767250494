<template>
  <div class="footer bg-grey-3">
    <q-item>
      <q-item-section>
        <q-item-label class="text-grey-8">
          {{ $t("global.footer.version", { value: "1.0.0" }) }}
        </q-item-label>
      </q-item-section>

      <q-item-section side class="item">
        {{ $t("global.footer.powered_by") }}
      </q-item-section>
      <q-item-section side>
        <q-avatar rounded size="60px">
          <img src="/flip/flip-icon.png" />
        </q-avatar>
      </q-item-section>
    </q-item>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

defineProps({
  influ: {
    type: Object,
    required: true,
  },
  company: {
    type: Object,
    required: true,
  },
  prodsByCat: {
    type: Object,
    required: true,
  },
});

/*
const goTo = (url) => {
  window.open(url, "_blank");
};

const removeLinkToStore = (n) => {
  return n.filter((n) => n.network !== "link_to_store");
};
*/
</script>

<style scoped>
.footer {
  margin-top: 50px;
}
</style>
