<template>
  <q-carousel
    arrows
    padding
    animated
    swipeable
    v-model="slide"
    control-type="regular"
    control-color="grey-8"
    control-text-color="white"
    class="bg-transparent full-height"
    transition-prev="slide-right"
    transition-next="slide-left"
  >
    <q-carousel-slide
      :key="i"
      :name="i"
      class="column"
      v-for="(group, i) in formatData(data, groupSize)"
    >
      <div class="row q-col-gutter-md">
        <div
          :key="y"
          :class="groupGrid"
          class="col-12 q-mt-sm"
          v-for="(item, y) in group.items"
        >
          <component
            :is="itemName"
            :data="item"
            :class="{ 'full-height': isFullHeight }"
          />
        </div>
      </div>
    </q-carousel-slide>
  </q-carousel>
</template>

<script>
import ProductCard from "./ProductCard.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CarouselMultiItensComponent",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProductCard,
  },

  props: {
    itemName: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    groupSize: {
      type: Number,
      default: 4,
    },
    groupGrid: {
      type: String,
      default: "col-md-3",
    },
    isFullHeight: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const formatData = (items, groupSize) => {
      const groupedItems = [];
      let currentGroup = [];

      for (let i = 0; i < items.length; i++) {
        currentGroup.push(items[i]);

        if (currentGroup.length === groupSize || i === items.length - 1) {
          groupedItems.push({ items: currentGroup });
          currentGroup = [];
        }
      }

      return groupedItems;
    };

    return {
      slide: ref(0),
      formatData,
    };
  },
});
</script>
