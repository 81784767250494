<template>
  <div v-if="!in_request">
    <div class="container" v-if="Object.keys(getCompanieDetails).length">
      <div class="text-center q-mb-md">
        <q-img :src="getCompanieDetails.logo" width="200px" />
      </div>
      <Menu :data="getProductsByCategories" @click="scrollTo" />
      <InfluHeader
        :ecommData="getInfluData"
        :ecommCustomization="getInfluEcomCustomization"
        v-if="Object.keys(getInfluData).length"
      />
    </div>

    <template v-if="Object.keys(getProductsByCategories).length">
      <div v-for="(prodsByCategories, i) in getProductsByCategories" :key="i">
        <div
          :id="prodsByCategories.title"
          class="text-white text-weight-thin text-center text-uppercase"
          v-html="prodsByCategories.title"
          :class="{
            'q-pa-md text-h4': $q.screen.width > 900,
            'q-pa-md text-h5': $q.screen.width < 900,
          }"
          :style="`background-color: ${
            layout.body_background !== '#E0E0E0'
              ? layout.menu_top_background
              : '#616161'
          };`"
        />
        <div class="container-cards">
          <CarouselMultiItensComponent
            :isFullHeight="false"
            :data="prodsByCategories.prods"
            :group-size="
              $q.screen.width < 900 ? 1 : $q.screen.width < 1300 ? 3 : 4
            "
            :group-grid="
              $q.screen.width < 900
                ? 'col-12'
                : $q.screen.width < 1300
                ? 'col-md-4'
                : 'col-md-3'
            "
            :item-name="'ProductCard'"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="bg-grey-3 q-pa-xl text-center text-h5 flex flex-center"
        style="height: 490px"
      >
        Não foram encontrados itens favoritos.
      </div>
    </template>

    <Footer
      :influ="getInfluData"
      :company="getCompanieDetails"
      :prodsByCat="getProductsByCategories"
      v-if="
        Object.keys(getInfluData).length &&
        Object.keys(getCompanieDetails).length
      "
    />
  </div>
</template>

<script setup>
import {
  setCustomLayoutLP,
  getCustomLayoutLP,
} from "@/shared/helpers/customLayoutHelper";
import CarouselMultiItensComponent from "./components/Carousel.vue";
import InfluHeader from "./components/InfluHeader.vue";
import { baseURL } from "@/shared/http/api";
import axios from "axios";

import { useActions, useGetters } from "vuex-composition-helpers";
import { /*useRouter,*/ useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";

const route = useRoute();
const in_request = ref(true);

const {
  getProductsByCategories,
  getInfluData,
  getCompanieDetails,
  getInfluEcomCustomization,
} = useGetters({
  getProductsByCategories: "public_landingpage/getProductsByCategories",
  getInfluData: "public_landingpage/getInfluData",
  getCompanieDetails: "public_landingpage/getCompanieDetails",
  getInfluEcomCustomization: "public_landingpage/getInfluEcomCustomization",
});

const {
  fetchInfluProductsByCategories,
  fetchInfluEcomCustomization,
  fetchCompanieDetails,
} = useActions({
  fetchInfluProductsByCategories:
    "public_landingpage/fetchInfluProductsByCategories",
  fetchInfluEcomCustomization: "public_landingpage/fetchInfluEcomCustomization",
  fetchCompanieDetails: "public_landingpage/fetchCompanieDetails",
});

const scrollTo = (title) => {
  document.getElementById(title).scrollIntoView({ behavior: "smooth" });
};

const layout = ref(getCustomLayoutLP("_LP"));

const fetchParam = (param) =>
  axios.get(
    `${baseURL}/api/v1/parameters/p/${route.params.childSlug}/${param}`
  );

onMounted(() => {
  const payload = {
    slug: route.params.childSlug,
    parentSlug: route.params.parentSlug,
  };

  Promise.all([
    fetchParam("custom_layout")
      .then(({ data }) => setCustomLayoutLP(data.value))
      .then(() => (layout.value = getCustomLayoutLP("_LP"))),
    fetchInfluProductsByCategories(payload),
    fetchInfluEcomCustomization(payload),
    fetchCompanieDetails({ slug: route.params.childSlug }),
  ]).finally(() => (in_request.value = false));
});
</script>

<style lang="scss" scoped>
.container {
  padding: 20px 200px;

  @media screen and (max-width: 1400px) {
    padding: 20px 100px;
  }

  @media screen and (max-width: 1200px) {
    padding: 20px 100px;
  }

  @media screen and (max-width: 900px) {
    padding: 20px 50px;
  }

  @media screen and (max-width: 700px) {
    padding: 20px 10px;
  }
}

.container-cards {
  padding: 0 400px;

  @media screen and (max-width: 1750px) {
    padding: 0 330px;
  }

  @media screen and (max-width: 1550px) {
    padding: 0 260px;
  }

  @media screen and (max-width: 1450px) {
    padding: 0 180px;
  }

  @media screen and (max-width: 1350px) {
    padding: 0 190px;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 150px;
  }

  @media screen and (max-width: 900px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 700px) {
    padding: 0 10px;
  }
}
</style>
